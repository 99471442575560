<template>
  <div class="num">
    <div class="chart">
      <img src="@/assets/data-screen/chart1.png" alt="" />
      <span>排水占比</span>
    </div>
    <div class="legends">
      <div class="item" v-for="item in list" :key="item.title">
        <div class="icon" :style="{ background: item.icon }"></div>
        <div class="title">{{ item.title }}</div>
        <div class="value">
          <Num :num="item.value" />
          %
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "城区1",
          icon: "#00FFEC",
          value: 35,
        },
        {
          title: "城区2",
          icon: "#FFFB00",
          value: 25,
        },
        {
          title: "城区3",
          icon: "#0072FF",
          value: 5,
        },
        {
          title: "城区4",
          icon: "#69B2FF",
          value: 10,
        },
        {
          title: "城区5",
          icon: "#FFB400",
          value: 5,
        },
        {
          title: "城区6",
          icon: "#00FFEC",
          value: 20,
        },
      ],
    };
  },
};
</script>


<style lang="less" scoped>
.num {
  display: flex;
  align-items: center;

  .chart {
    position: relative;
    img {
      height: 20vh;
    }
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      font-size: 2vh;
    }
  }

  .legends {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2vh;
    width: 18vw;
    margin-left: 2vw;

    .item {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding-bottom: 0.5em;
      display: flex;
      align-items: center;

      .icon {
        height: 1em;
        width: 1em;
        margin-right: 0.5em;
      }
      .title {
        color: #999;
      }
      .value {
        margin-left: auto;
      }
    }
  }
}
</style>