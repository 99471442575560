<template>
  <div class="sewage">
    <img class="chart" src="@/assets/data-screen/chart4.png" alt="" />
    <div class="legends">
      <div class="item">
        <div class="title">
          <div class="icon" style="background: #71ffec"></div>
          <div>今日累计处理量</div>
        </div>
        <div class="info">
          <div class="value" style="color: #71ffec">
            <Num :num="13212" />
          </div>
          <div>万吨</div>
        </div>
      </div>
      <div class="item" style="margin-top: 1vh">
        <div class="title">
          <div class="icon" style="background: #ffae00"></div>
          <div>设计承载量</div>
        </div>
        <div class="info">
          <div class="value" style="color: #ffae00">
            <Num :num="24243" />
          </div>
          <div>万吨</div>
        </div>
      </div>
    </div>

    <div class="data">
      <div class="item" v-for="item in list" :key="item.title">
        <img src="@/assets/data-screen/data-bg.png" alt="" />
        <div class="value">
          {{ item.value }}
        </div>
        <div class="title">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "COD(mg/L)",
          value: 13,
        },
        {
          title: "氨氮(mg/L)",
          value: 0.16,
        },
        {
          title: "总磷(mg/L)",
          value: 0.03,
        },
        {
          title: "总氨mg/L)",
          value: 13,
        },
        {
          title: "PH值",
          value: 0.16,
        },
        {
          title: "粪大肠菌群(MPN/L)",
          value: 0.03,
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.sewage {
  margin-top: -2vh;
  display: flex;
  align-items: center;
  margin-bottom: 3vh;
}

.chart {
  margin-left: -2vw;
  height: 20vh;
  margin-right: 1vw;
}

.legends {
  width: 10em;
  margin-left: -2vw;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 0.5em;
  }
  .icon {
    height: 1em;
    width: 1em;
    margin-right: 0.5em;
  }
  .info {
    margin-top: 0.2vh;
    display: flex;
    align-items: baseline;
  }
  .value {
    font-size: 1.5em;
  }
}

.data {
  width: 50%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1vw;

  .item {
    position: relative;
    img {
      width: 100%;
    }
    .value {
      position: absolute;
      top: -5%;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 1.6em;
      font-weight: bold;
      color: #71ffec;
    }
    .title {
      position: absolute;
      top: 80%;
      left: 0;
      right: 0;
      text-align: center;
      color: #b0edd3;
    }
  }
}
</style>