<template>
  <div class="card-header">
    <img src="@/assets/data-screen/card-header.png" alt="" />
    <span>{{ title }}</span>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="less" scoped>
.card-header {
  position: relative;
  margin-bottom: 1.5vh;

  img {
    width: 40%;
  }

  span {
    position: absolute;
    left: 2em;
    font-weight: bold;
    font-size: 1.6vh;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>