<template>
  <div class="list">
    <div class="item" v-for="item in list" :key="item.title">
      <img :src="item.icon" alt="" />
      <div>
        <div class="label">{{ item.title }}</div>
        <div>
          <span class="value">
            <Num :num="item.value" />
          </span>
          <span>{{ item.unit }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "雨水管线",
          icon: require("@/assets/data-screen/icon1.png"),
          value: 87,
          unit: "km",
        },
        {
          title: "污水管线",
          icon: require("@/assets/data-screen/icon2.png"),
          value: 44,
          unit: "km",
        },
        {
          title: "排口",
          icon: require("@/assets/data-screen/icon3.png"),
          value: 1427,
          unit: "个",
        },
        {
          title: "截流井",
          icon: require("@/assets/data-screen/icon6.png"),
          value: 18,
          unit: "个",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 2vh;
  margin-bottom: 4vh;

  .item {
    display: flex;
    align-items: center;

    img {
      height: 4em;
      margin-right: 1em;
    }

    .value {
      font-size: 2em;
      padding-right: 0.2em;
      color: #00ffec;
    }
  }
}
</style>