<template>
  <div class="table">
    <div class="left row header">
      <div class="col center">序号</div>
      <div class="col center">上报时间</div>
      <div class="col" style="flex: 2">上报内容</div>
      <div class="col center">上报⼈员</div>
    </div>

    <transition-group name="fade" tag="div" class="transition-wrapper">
      <div class="left row" v-for="item in list" :key="item.index">
        <div class="col center">{{ item.index }}</div>
        <div class="col center">{{ item.date }}</div>
        <div class="col" style="flex: 2">{{ item.title }}</div>
        <div class="col center">{{ item.user }}</div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          index: 1,
          title: "XXX工厂偷排污水",
          date: " 2023/01/23",
          user: "管理员",
        },
        {
          index: 2,
          title: "XXX小区污水外溢",
          date: "2023/02/01",
          user: "管理员",
        },
        {
          index: 3,
          title: "XXX河道巡检养护到位",
          date: "2023/02/01",
          user: "管理员",
        },
      ],
    };
  },

  mounted() {
    this.timer = setInterval(() => {
      const item = this.list.shift();
      this.list.push(item);
    }, 3000);
  },
};
</script>


<style lang="less" scoped>
.table {
  .row {
    background-color: rgba(255, 255, 255, 0.06);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 4px;
      height: 1px;
      background-color: #71ffec;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 4px;
      height: 1px;
      background-color: #71ffec;
    }

    .col {
      flex: 1;
      padding: 0.8vh;
    }
  }

  .header {
    background-color: rgba(113, 255, 236, 0.2);
    color: #71ffec;
    font-weight: bold;
  }
}
</style>