<template>
  <div class="page">
    <div class="header">
      <img class="bg" src="@/assets/data-screen/header-bg.png" alt />
      <span class="title">淮安清江浦区智慧水务</span>

      <div class="extra">
        <div class="date">{{ date.year }}.{{ date.month }}.{{ date.date }}</div>
        <div class="divider"></div>
        <div class="weather">
          <img
            v-if="weather.wea_img"
            :src="`https://adm.ac.cn/static/img/weather/mango/${weather.wea_img}.png`"
            alt
          />
          <span>{{ weather.tem }}℃</span>
        </div>
      </div>
    </div>

    <div class="body">
      <div>
        <CardHeader title="排水设施" />
        <Overview />
        <CardHeader title="排水户统计" />
        <Statistic />
        <CardHeader title="分区排水量" />
        <Num />
      </div>
      <div>
        <Map />
      </div>
      <div>
        <CardHeader title="供水情况" />
        <Water />
        <CardHeader title="污水处理情况" />
        <Sewage />
        <div style="margin-top: -1vh">
          <CardHeader title="舆情上报" />
          <Report />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardHeader from "./components/card-header.vue";
import Overview from "./components/overview.vue";
import Statistic from "./components/statistic.vue";
import Num from "./components/num.vue";
import Map from "./components/map.vue";
import Water from "./components/water.vue";
import Sewage from "./components/sewage.vue";
import Report from "./components/report.vue";
export default {
  components: {
    CardHeader,
    Overview,
    Statistic,
    Num,
    Map,
    Water,
    Sewage,
    Report,
  },

  data() {
    return {
      date: {},
      weather: {},
    };
  },
  mounted() {
    const now = new Date();
    this.date = {
      year: now.getFullYear(),
      month: String(now.getMonth() + 1).padStart(2, "0"),
      date: String(now.getDate()).padStart(2, "0"),
    };
    fetch(
      "https://adm.ac.cn/Api/Weather/getForcast7?token=njszy&city=淮安清江浦区"
    )
      .then((res) => res.json())
      .then((res) => {
        if (res && res.info && Array.isArray(res.info.data)) {
          this.weather = res.info.data[0] ?? {};
        }
      });
  },
};
</script>


<style lang="less" scoped>
.page {
  height: 100vh;
  padding-top: 0.5em;
  box-sizing: border-box;
  background: radial-gradient(#08383a 0%, #011414 100%);
  font-size: 1.6vh;
  color: #fff;
  position: relative;

  .header {
    position: relative;
    .bg {
      display: block;
      width: 100%;
    }
    .title {
      position: absolute;
      top: 0%;
      left: 50%;
      transform: translateX(-50%);

      letter-spacing: 0.05em;
      font-size: 4.8vh;
      font-family: YouSheBiaoTiHei;
      background-image: linear-gradient(to bottom, #ffffff, #71ffec);
      -webkit-background-clip: text;
      color: transparent;
    }
    .extra {
      position: absolute;
      top: 40%;
      right: 1vw;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 2.4vh;

      .divider {
        width: 1px;
        background-color: #000;
        margin: 0 0.5em;
        height: 1em;
      }

      .weather {
        display: flex;
        align-items: center;
        img {
          height: 2.4vh;
          margin-right: 0.5em;
        }
      }
    }
  }

  .body {
    padding: 0 1vw;
    display: flex;
  }
}
</style>