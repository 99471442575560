<template>
  <div>
    <div class="data">
      <div class="item" v-for="item in list" :key="item.title">
        <div class="title">{{ item.title }}</div>
        <div class="info">
          <div class="value">
            <Num :num="item.value" />
          </div>
          <img
            v-if="item.type === 'up'"
            src="@/assets/data-screen/arrow-up.png"
            alt=""
          />
          <img v-else src="@/assets/data-screen/arrow-down.png" alt="" />
          <div class="num"><Num :num="item.progress" />%</div>
        </div>
        <div class="progress-bar">
          <div class="progress" :style="{ width: item.progress + '%' }"></div>
        </div>
      </div>
    </div>

    <img class="chart" src="@/assets/data-screen/chart2.png" alt="" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "今日供水量(万吨)",
          value: 6800,
          progress: 75,
          type: "up",
        },
        {
          title: "昨日供⽔量(万吨)",
          value: 2400,
          progress: 65,
          type: "up",
        },
        {
          title: "本月累计供水量(万吨)",
          value: 551.23,
          progress: 45,
          type: "down",
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.data {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2vw;
  margin-bottom: 3vh;

  .item {
    .title {
      color: #e9fdf9;
      margin-bottom: 0.5vh;
    }
    .info {
      display: flex;
      align-items: center;
      margin-bottom: 1vh;
      .value {
        font-size: 1.6em;
        color: #99f5df;
      }
      img {
        margin-left: auto;
        height: 1em;
        margin-right: 0.5em;
      }
    }
    .progress-bar {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 1em;
      height: 0.6em;
      width: 100%;
      .progress {
        height: 100%;

        background: linear-gradient(90deg, #fad961 0%, #f76b1c 100%);
        border-radius: 1em;
        opacity: 0.9;
      }
    }
  }
  .item:nth-child(2) {
    .progress-bar {
      .progress {
        background: linear-gradient(90deg, #eaf85d 0%, #6fcb15 100%);
      }
    }
  }
  .item:nth-child(3) {
    .progress-bar {
      .progress {
        background: linear-gradient(90deg, #bbeddc 0%, #00c7ac 100%);
      }
    }
  }
}

.chart {
  display: block;
  height: 20vh;
  margin: 0 auto 2vh;
}
</style>